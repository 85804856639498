import CustomAutocomplete from 'components/custom-autocomplete';
import CustomButton from 'components/custom-button';
import CustomModalTwoButtons from 'components/custom-modal-two-buttons';
import Header from 'components/general-components/header';
import { PageLoading } from 'components/page-loading';
import { ProcessingClientData, useClientData } from 'components/simulator-data/processing/hooks/use-client-data';
import { SimulatorState } from 'components/simulator-data/processing/hooks/use-simulator-state';
import { EyeButton } from 'components/styled-input/styles';
import { ORGANIZATION_NAME } from 'config/constants';
import { useOrganizationSubsidiaries } from 'hooks/use-organization-subsidiaries';
import Eye from 'images/eye.svg';
import { isEmpty } from 'lodash';
import { Client } from 'model/client';
import { Authority } from 'model/enums/authority';
import { ContractStatus } from 'model/enums/contract-status';
import FinancingStatusType from 'model/enums/financing-status-type';
import FinancingPaymentType from 'model/enums/financingPaymentType';
import { FormStepType } from 'model/enums/form-step-type';
import { HeaderVariants } from 'model/enums/header-variants';
import { LocalStorageKeys } from 'model/enums/local-storage-keys';
import { OrganizationName } from 'model/enums/organization-name';
import { RegistrationStatus } from 'model/enums/registration-status';
import { RoutePath } from 'model/enums/route-path';
import StepType from 'model/enums/step-type';
import { Financing } from 'model/financing';
import { Internal } from 'model/internals';
import { OrganizationSubsidiaries } from 'model/landing-page';
import { MeuCrediarioResponse } from 'model/multi-credit-response';
import { Organization } from 'model/organization';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiEdit, FiEdit2, FiPlusCircle } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { IRootState } from 'reducer';
import clientDataService from 'services/client-data-service';
import clientService, { ClientService } from 'services/client-service';
import financingService from 'services/financing-service';
import organizationService from 'services/organization-service';
import organizationSystemStepsService from 'services/organization-system-steps-service';
import { hasAuthorities, verifyStatus } from 'shared/util/utils';
import { useTheme } from 'styled-components';
import { EditButton } from '../customerAnalysis/components/tabDetails/styles';
import { Status } from '../dashboard/components/status';
import { RegisterDataDisplay } from './components/RegisterDataDisplay';
import { AnalisysInfoModal } from './components/analisysInfoModal';
import { AnalisysModal } from './components/analisysModal';
import { AnalysisCard } from './components/analysisCard';
import { ReactivateAccountAlert } from './components/reactivateAccountAlert';
import {
  AddAnalistButton,
  AddButton,
  Alignor,
  AnalisisStatus,
  ButtonContainer,
  CardsContainer,
  DataContainer,
  FirstAreaContainer,
  MainContainer,
  PageContainer,
  SecondAreaContainer,
  ShadowBox,
  StyledArrow,
  StyledAutocompleteContainer,
  StyledStatusContainer,
  ThirdAreaContainer,
  TitleArea,
  VerticalLine,
} from './styles';

export enum ModalConfig {
  ACTION_SELECTOR = 'ACTION_SELECTOR',
  ANALIST_SELECTOR = 'ANALIST_SELECTOR',
  SHOW_SCORE = 'SHOW_SCORE',
  REPROVE = 'REPROVE',
}
export interface AnalisisProps {
  id: string;
  status: string;
  requestDate: string;
  value: string;
  portion: string;
}

export interface ModalConfigProps {
  title: string;
  type: string;
  description?: string;
  target?: string | number;
}

export const ClientDataScreen = () => {
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const theme = useTheme();
  const { t } = useTranslation();
  const [clientData, setClientData] = useState<Client>();
  const [organizationData, setOrganizationData] = useState<Organization>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showAnalisysInfoModal, setShowAnalisysInfoModal] = useState<boolean>(false);
  const [analisis, setAnalisis] = useState<Financing[]>([]);
  const [currentModal, setCurrentModal] = useState(0);
  const [isVerified, setIsVerified] = useState<boolean | null>(null);
  const account = useSelector((state: IRootState) => state.authentication.account);
  const [modalConfig, setModalConfig] = useState<ModalConfigProps[]>([
    {
      title: t('analisisModal.registerAnalisis'),
      type: ModalConfig.ACTION_SELECTOR,
    },
    {
      title: t('analisisModal.addAnalist'),
      type: ModalConfig.ANALIST_SELECTOR,
    },
    {
      title: t('analisisModal.highScore'),
      description: t('analisisModal.highScore_description'),
      type: ModalConfig.SHOW_SCORE,
    },
    {
      title: t('analisisModal.REJECTED'),
      type: ModalConfig.REPROVE,
    },
  ]);

  const [orgStepTypes, setOrgStepTypes] = useState<StepType[]>([]);
  const organizationId = useSelector((state: IRootState) => state.systemParameters.systemParameters?.fieldType?.organization.id);

  const { organizationSubsidiaries } = useOrganizationSubsidiaries();
  const [selectedSubisidiarie, setSelectedSubisidiarie] = useState<OrganizationSubsidiaries>();
  const [isShowSelectSubsidiaryModal, setIsShowSelectSubsidiaryModal] = useState<boolean>(false);
  const [isLoadingButtonSelectSubsidiary, setIsLoadingButtonSelectSubsidiary] = useState<boolean>(false);
  const [assignedInternals, setAssignedInternals] = useState<Internal[]>([]);

  const getStatus = (target, style?: boolean) => {
    switch (target) {
      case RegistrationStatus.ADJUST:
        return style ? '#F7B330' : t(`enum.registrationStatus.${RegistrationStatus.ADJUST}`);
      case RegistrationStatus.ADJUSTED:
        return style ? '#0DB052' : t(`enum.registrationStatus.${RegistrationStatus.ADJUSTED}`);
      case RegistrationStatus.APPROVED:
        return style ? '#0DB052' : t(`enum.registrationStatus.${RegistrationStatus.APPROVED}`);
      case RegistrationStatus.NEW:
        return style ? '#0DB052' : t(`enum.registrationStatus.${RegistrationStatus.NEW}`);
      case RegistrationStatus.PRE_REJECTED:
        return style ? '#FC4F4F' : t(`enum.registrationStatus.${RegistrationStatus.PRE_REJECTED}`);
      case RegistrationStatus.REJECTED:
        return style ? '#FC4F4F' : t(`enum.registrationStatus.${RegistrationStatus.REJECTED}`);
      case RegistrationStatus.UPDATED:
        return style ? '#0DB052' : t(`enum.registrationStatus.${RegistrationStatus.UPDATED}`);
      case RegistrationStatus.VALIDATION:
        return style ? '#F7B330' : t(`enum.registrationStatus.${RegistrationStatus.VALIDATION}`);
      case RegistrationStatus.IN_PROGRESS:
        return style ? '#F7B330' : t(`enum.registrationStatus.${RegistrationStatus.IN_PROGRESS}`);
      case RegistrationStatus.FINISHED:
        return style ? '#0DB052' : t(`enum.registrationStatus.${RegistrationStatus.FINISHED}`);
      case RegistrationStatus.CANCELLED:
        return style ? '#FC4F4F' : t(`enum.registrationStatus.${RegistrationStatus.CANCELLED}`);
      case RegistrationStatus.PENDING_PAYMENT:
        return style ? '#F7B330' : t(`enum.registrationStatus.${RegistrationStatus.PENDING_PAYMENT}`);
      case RegistrationStatus.PENDING_SIGN:
        return style ? '#F7B330' : t(`enum.registrationStatus.${RegistrationStatus.PENDING_SIGN}`);
      case RegistrationStatus.CREATING_ACCOUNT_IUGU:
        return style ? '#F7B330' : t(`enum.registrationStatus.${RegistrationStatus.CREATING_ACCOUNT_IUGU}`);
      case RegistrationStatus.ERROR_ACCOUNT_IUGU:
        return style ? '#FC4F4F' : t(`enum.registrationStatus.${RegistrationStatus.ERROR_ACCOUNT_IUGU}`);

      default:
        return style ? '#525242' : t('enum.registrationStatus.UNKNOWN');
    }
  };

  useEffect(() => {
    getSingleClientData();
  }, [showModal]);

  const handleShowModal = target => {
    setShowModal(true);
    setCurrentModal(target);
  };
  const handleShowInfoModal = () => {
    setShowAnalisysInfoModal(true);
  };

  useEffect(() => {
    getStepType();
    getBiometricVerification();
    getOrganizationData();
  }, []);

  const getOrganizationData = async () => {
    const res = await organizationService.getCurrentOrganization();
    setOrganizationData(res);
  };

  // useEffect(() => {
  // TODO: Rever lógica
  //   InternalsService()
  //     .getFinancyInternalsById(Number(params.id))
  //     .then(res => setAssignedInternals(res.internals));
  // }, []);

  const getBiometricVerification = async () => {
    if (Number(params.id)) {
      const res = await clientService.getBiometricsStatusAndClientAuthentication(Number(params.id));
      setIsVerified(res.isVerified);
    }
  };

  const getStepType = async () => {
    const res = await organizationSystemStepsService.getStepAdmin();
    const orgTypes = res
      .filter(
        orgType =>
          orgType.stepType === StepType.CREDIT_ANTICIPATION || orgType.stepType === StepType.CCB || orgType.stepType === StepType.PROCEDURE
      )
      .map(type => type.stepType);

    const filtered = orgTypes.filter((orgType, idx) => orgTypes.indexOf(orgType) === idx);

    if (filtered.length > 0) {
      setOrgStepTypes(filtered as StepType[]);
    }
  };

  const handleCloseSelectSubsidiaryModal = () => {
    setSelectedSubisidiarie(undefined);
    setIsShowSelectSubsidiaryModal(false);
    setIsLoadingButtonSelectSubsidiary(false);
  };
  const [myCredit, setMyCredit] = useState<MeuCrediarioResponse>();
  const getSingleClientData = () => {
    if (ORGANIZATION_NAME === OrganizationName.MONJUA) {
      clientDataService.getClientMeuCrediario(Number(params.id)).then(res => setMyCredit(res));
    }

    clientDataService.getClientSummary(Number(params.id)).then(res => {
      if (clientData != res) {
        setClientData(res);
      }
      if (res.finances) {
        setAnalisis(res.finances);
      }
      if (
        ![
          FinancingStatusType.NEW,
          FinancingStatusType.VALIDATION,
          FinancingStatusType.IN_PROGRESS,
          FinancingStatusType.ADJUST,
          FinancingStatusType.ADJUSTED,
        ].some(item => res.finances?.map(item => item.financingStatus).includes(item))
      ) {
        if (res.finances?.map(item => item.financingStatus).includes(FinancingStatusType.FINISHED)) {
          let analisyFinished = res.finances.find(item => item.financingStatus === FinancingStatusType.FINISHED);

          if (analisyFinished?.id) {
            financingService.getAllContractsFinancing(analisyFinished?.id).then(response => {
              if (response.content[0].status === ContractStatus.FINISHED) {
                setAnalisisHaveNoNewOrProgress(true);
              }
            });
          }
        } else {
          setAnalisisHaveNoNewOrProgress(true);
        }
      }
      handleCloseSelectSubsidiaryModal();
    });
  };
  const [analisisHaveNoNewOrProgress, setAnalisisHaveNoNewOrProgress] = useState<boolean>(false);

  if (clientData == null) {
    return <PageLoading />;
  }

  const handleChangeSubsidiary = () => {
    if (selectedSubisidiarie != null && selectedSubisidiarie.id !== clientData.organizationSubsidiary?.id) {
      setIsLoadingButtonSelectSubsidiary(true);

      if (clientData.id) {
        ClientService()
          .changeClientSubsidiary(clientData.id, selectedSubisidiarie.id)
          .then(() => getSingleClientData());
      }
    } else {
      handleCloseSelectSubsidiaryModal();
    }
  };

  const { getClientData } = useClientData();

  const handleEdit = async () => {
    if (ORGANIZATION_NAME === OrganizationName.MULTTIPLO) {
      history.push(`/admin/cadastros/editar/${clientData?.id}`);
    } else if (
      !clientData?.attachments?.find(elem => elem.type === FormStepType.SELFIE) &&
      clientData?.registrationStatus !== RegistrationStatus.NEW &&
      clientData?.registrationStatus !== RegistrationStatus.PRE_REJECTED
    ) {
      if (clientData?.selfieKey) {
        history.push(`/selfie/${clientData?.id}/admin/qrcode/${clientData?.selfieKey}/`);
      } else {
        if (clientData?.id) {
          clientService.generateSelfieKeyAdmin(clientData?.id).then(res => {
            return history.replace(`/selfie/${clientData?.id}/admin/qrcode/${res?.selfieKey}`);
          });
        }
      }
    } else if (
      clientData?.registrationStatus !== RegistrationStatus.NEW &&
      clientData?.registrationStatus !== RegistrationStatus.PRE_REJECTED
    ) {
      history.push(`/admin/cadastros/editar/${clientData?.id}`);
    } else {
      if (clientData?.registrationKey) {
        const getSimulatorData: ProcessingClientData | undefined = await getClientData(clientData?.registrationKey ?? '');
        const simulator = {
          ...getSimulatorData?.client?.finances?.[0],
          client: {
            id: getSimulatorData?.client?.id,
          },
        };
        localStorage.setItem(LocalStorageKeys.REGISTRATION_KEY, JSON.stringify(clientData?.registrationKey));
        history.push(`${RoutePath.SIMULATOR_PROCESSING_ADMIN}/${getSimulatorData?.client?.id}`, {
          sendSimulator: simulator,
        } as SimulatorState);
      } else if (clientData?.selfieKey) {
        history.push(`/selfie/${clientData?.id}/admin/qrcode/${clientData?.selfieKey}/`);
      }
    }
  };

  return (
    <>
      {showModal && <AnalisysModal modalConfiguration={modalConfig[currentModal]} setShowModal={setShowModal} />}
      {showAnalisysInfoModal && (
        <AnalisysInfoModal
          status={t(`enum.filterAllStatus.${clientData?.registrationStatus}`)}
          setShowModal={setShowAnalisysInfoModal}
          comment={clientData.internalComment}
        />
      )}
      <PageContainer>
        <Header variant={HeaderVariants.SECONDARY} />
        <MainContainer>
          <FirstAreaContainer>
            <TitleArea onClick={() => history.replace('/admin/cadastros')}>
              <StyledArrow />
              {t('clientData.title')}
            </TitleArea>
            {clientData.organizationSubsidiary?.name != null &&
              hasAuthorities(account?.authorities, [Authority.ROLE_ADMIN, Authority.ROLE_INTERNAL_ADMIN]) && (
                <StyledStatusContainer>
                  <Status text={clientData.organizationSubsidiary.name} color={FinancingPaymentType.ORGANIZATION} />
                  <EditButton onClick={() => setIsShowSelectSubsidiaryModal(true)} type="button">
                    <FiEdit color={theme.color.primaryColor} />
                  </EditButton>
                </StyledStatusContainer>
              )}
            {ORGANIZATION_NAME === OrganizationName.MULTTIPLO &&
              clientData.organizationSubsidiary?.name == null &&
              hasAuthorities(account?.authorities, [Authority.ROLE_ADMIN, Authority.ROLE_INTERNAL_ADMIN]) && (
                <StyledStatusContainer>
                  <Status text={t(`org.${ORGANIZATION_NAME}`)} color={FinancingPaymentType.ORGANIZATION} />
                  <EditButton onClick={() => setIsShowSelectSubsidiaryModal(true)} type="button">
                    <FiEdit color={theme.color.primaryColor} />
                  </EditButton>
                </StyledStatusContainer>
              )}
            <ButtonContainer>
              {clientData?.registrationStatus === RegistrationStatus.APPROVED
                ? hasAuthorities(account?.authorities, [
                    Authority.ROLE_ADMIN,
                    Authority.ROLE_INTERNAL_ADMIN,
                    Authority.ROLE_MANAGER,
                    Authority.ROLE_SELLER,
                  ]) && (
                    <CustomButton icon={<FiEdit2 size={20} color={theme.color.primaryColor} />} isInvertColor onClick={handleEdit}>
                      {t('clientData.edit')}
                    </CustomButton>
                  )
                : hasAuthorities(account?.authorities, [
                    Authority.ROLE_ADMIN,
                    Authority.ROLE_INTERNAL_ADMIN,
                    Authority.ROLE_MANAGER,
                    Authority.ROLE_SELLER,
                    Authority.ROLE_ANALYST,
                  ]) && (
                    <CustomButton icon={<FiEdit2 size={20} color={theme.color.primaryColor} />} isInvertColor onClick={handleEdit}>
                      {t('clientData.edit')}
                    </CustomButton>
                  )}
              {clientData?.registrationStatus !== RegistrationStatus.NEW &&
                clientData?.registrationStatus !== RegistrationStatus.PRE_REJECTED &&
                hasAuthorities(account?.authorities, [Authority.ROLE_ADMIN, Authority.ROLE_INTERNAL_ADMIN]) && (
                  <CustomButton onClick={() => handleShowModal(0)}>{t('clientData.registerAnalysis')}</CustomButton>
                )}
              {clientData?.registrationStatus === RegistrationStatus.NEW &&
                hasAuthorities(account?.authorities, [Authority.ROLE_ADMIN, Authority.ROLE_INTERNAL_ADMIN]) && (
                  <CustomButton onClick={() => handleShowModal(3)}>{t('clientData.registerAnalysis')}</CustomButton>
                )}
            </ButtonContainer>
          </FirstAreaContainer>
          {isVerified != null && !isVerified && <ReactivateAccountAlert clientId={Number(params.id)} />}
          <SecondAreaContainer>
            <Alignor>
              <DataContainer>
                <span>{t('clientData.lastChange')}</span>
                <span>{moment(clientData?.lastModifiedDate).format('DD/MM/YY')}</span>
              </DataContainer>

              {!isEmpty(myCredit?.createdDate) && (
                <DataContainer>
                  <span>{t('clientData.createdDate')}</span>
                  <span>{moment(myCredit?.createdDate).format('DD/MM/YY')}</span>
                </DataContainer>
              )}

              <VerticalLine />
              {clientData?.registrationStatus === RegistrationStatus.APPROVED && clientData?.approvedDate && (
                <>
                  <DataContainer>
                    <span>{t('clientData.aprovalDate')}</span>
                    <span>{moment(clientData?.approvedDate).format('DD/MM/YY')}</span>
                  </DataContainer>
                  <VerticalLine />
                </>
              )}
              {clientData?.partnerCode && (
                <>
                  <DataContainer>
                    <span>{t('clientData.partnerCode')}</span>
                    <span>{clientData?.partnerCode}</span>
                  </DataContainer>
                  <VerticalLine />
                </>
              )}
              <DataContainer>
                <span>{t('clientData.registerStatus')}</span>
                <AnalisisStatus $styling={getStatus(clientData?.registrationStatus, true)}>
                  {getStatus(clientData?.registrationStatus)}
                </AnalisisStatus>
              </DataContainer>
              <VerticalLine />
              {/* TODO: Rever lógica
              {assignedInternals.length > 0 ? (
                <DataContainer>
                  <span>{t('clientData.analist')}</span>
                  <span>{assignedInternals?.[0].name}</span>
                </DataContainer>
              ) : (
                hasAuthorities(account?.authorities, [Authority.ROLE_ADMIN, Authority.ROLE_MANAGER, Authority.ROLE_SELLER]) && (
                  <AddAnalistButton onClick={() => handleShowModal(1)} theme={theme}>
                    <BsPlusCircle />
                    {t('clientData.addAnalist')}
                  </AddAnalistButton>
                )
              )} */}
              {(clientData.registrationStatus === RegistrationStatus.ADJUST ||
                clientData.registrationStatus === RegistrationStatus.ADJUSTED ||
                clientData.registrationStatus === RegistrationStatus.APPROVED ||
                clientData.registrationStatus === RegistrationStatus.REJECTED) && (
                <>
                  <AddAnalistButton onClick={() => handleShowInfoModal()} theme={theme}>
                    <EyeButton src={Eye} />
                    {t('clientData.seeAnalysis')}
                  </AddAnalistButton>
                </>
              )}
            </Alignor>
            <RegisterDataDisplay myCredit={myCredit} registerData={clientData} organizationData={organizationData} />
          </SecondAreaContainer>
          {orgStepTypes.includes(StepType.CCB) && (
            <CardsContainer>
              {analisis.length > 0 && (
                <ThirdAreaContainer theme={theme}>
                  {analisis.map((elem, index) => (
                    <AnalysisCard getStatus={getStatus} analisis={elem} key={index} />
                  ))}
                </ThirdAreaContainer>
              )}
              {analisis.length > 2 && <ShadowBox />}
              {analisisHaveNoNewOrProgress &&
                (clientData?.registrationStatus === RegistrationStatus.APPROVED ||
                  clientData?.registrationStatus === RegistrationStatus.UPDATED ||
                  clientData?.registrationStatus === RegistrationStatus.IN_PROGRESS ||
                  clientData?.registrationStatus === RegistrationStatus.FINISHED ||
                  clientData?.registrationStatus === RegistrationStatus.PENDING_PAYMENT ||
                  clientData?.registrationStatus === RegistrationStatus.PENDING_SIGN) &&
                hasAuthorities(account?.authorities, [
                  Authority.ROLE_ADMIN,
                  Authority.ROLE_INTERNAL_ADMIN,
                  Authority.ROLE_MANAGER,
                  Authority.ROLE_SELLER,
                  Authority.ROLE_FINANCIAL,
                  Authority.ROLE_ATTENDANT,
                ]) && (
                  <AddButton $emptyList={analisis.length <= 1} onClick={() => history.push(`/admin/nova-analise/${params.id}`)}>
                    <FiPlusCircle size={24} color={theme.color.primaryColor} />
                    <span>{analisis.length <= 1 ? t('clientData.addAnalysis') : t('clientData.addProcedureShort')}</span>
                  </AddButton>
                )}
            </CardsContainer>
          )}
          {orgStepTypes.includes(StepType.PROCEDURE) && (
            <CardsContainer>
              {analisis.length > 0 && (
                <ThirdAreaContainer theme={theme}>
                  {analisis.map((elem, index) => (
                    <AnalysisCard getStatus={getStatus} analisis={elem} key={index} />
                  ))}
                </ThirdAreaContainer>
              )}
              {analisis.length > 2 && <ShadowBox />}
              {analisis.findIndex(item =>
                verifyStatus(item.financingStatus, [
                  FinancingStatusType.NEW,
                  FinancingStatusType.VALIDATION,
                  FinancingStatusType.IN_PROGRESS,
                  FinancingStatusType.ADJUST,
                  FinancingStatusType.ADJUSTED,
                ])
              ) === -1 &&
                (clientData?.registrationStatus === RegistrationStatus.APPROVED ||
                  clientData?.registrationStatus === RegistrationStatus.UPDATED ||
                  clientData?.registrationStatus === RegistrationStatus.IN_PROGRESS ||
                  clientData?.registrationStatus === RegistrationStatus.FINISHED ||
                  clientData?.registrationStatus === RegistrationStatus.PENDING_PAYMENT ||
                  clientData?.registrationStatus === RegistrationStatus.PENDING_SIGN) &&
                hasAuthorities(account?.authorities, [
                  Authority.ROLE_ADMIN,
                  Authority.ROLE_INTERNAL_ADMIN,
                  Authority.ROLE_MANAGER,
                  Authority.ROLE_SELLER,
                  Authority.ROLE_FINANCIAL,
                  Authority.ROLE_ATTENDANT,
                ]) &&
                !analisis.find(item => verifyStatus(item.financingStatus, [FinancingStatusType.APPROVED])) && (
                  <AddButton $emptyList={analisis.length <= 1} onClick={() => history.push(`/admin/novo-procedimento/${params.id}`)}>
                    <FiPlusCircle size={24} color={theme.color.primaryColor} />
                    <span>{analisis.length <= 1 ? t('clientData.addProcedure') : t('clientData.addProcedureShort')}</span>
                  </AddButton>
                )}
            </CardsContainer>
          )}
        </MainContainer>
      </PageContainer>

      <CustomModalTwoButtons
        large
        formId="subsidiary-form"
        isShowModal={isShowSelectSubsidiaryModal}
        onCloseModal={() => handleCloseSelectSubsidiaryModal()}
        title={t('admin.dashboard.editBranch')}
        children={
          <StyledAutocompleteContainer>
            <CustomAutocomplete
              data={organizationSubsidiaries}
              keysValuesToDisplayed={['name']}
              label={t('admin.dashboard.branch')}
              optionSelected={setSelectedSubisidiarie}
              defaultValue={organizationSubsidiaries.filter(subsidiary => subsidiary.id === clientData.organizationSubsidiary?.id)[0]}
            />
          </StyledAutocompleteContainer>
        }
        firstButtonText={t('global.button.cancel')}
        secondButtonText={t('global.button.save')}
        handleClickFirstButton={() => handleCloseSelectSubsidiaryModal()}
        handleClickSecondButton={() => handleChangeSubsidiary()}
        loading={isLoadingButtonSelectSubsidiary}
      />
    </>
  );
};
