import { useState } from 'react';
import { Container, DropDownContainer } from './styles';
import { ReactComponent as MoreVerticalIconSvg } from 'images/more-vertical.svg';
import { useTranslation } from 'react-i18next';
import { OrganizationSubsidiaries } from 'model/landing-page';
import { useHistory } from 'react-router-dom';
import { SubsidiariesService } from 'services/subsidiaries-service';
import { Subsidiary } from 'model/subsidiaries';

interface StatusProps {
  branch: Subsidiary;
}

export const MoreMenu = ({ branch }: StatusProps) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const activateBranch = async () => {
    if (branch.id == null) return;
    await SubsidiariesService()
      .activateBranch(branch.id)
      .then(res => window.location.reload());
  };

  const inactivateBranch = async () => {
    if (branch.id == null) return;
    await SubsidiariesService()
      .inactivateBranch(branch.id)
      .then(res => window.location.reload());
  };

  return (
    <Container>
      <button
        className="edit"
        onClick={() => {
          setOpenEditModal(!openEditModal);
        }}
      >
        <MoreVerticalIconSvg />
      </button>
      {openEditModal && (
        <DropDownContainer>
          <button
            className="edit"
            onClick={() => {
              history.push(`/admin/filial/${branch.id}`);
              setOpenEditModal(!openEditModal);
            }}
          >
            {t('admin.branch.seeData')}
          </button>
          <button
            className="edit"
            onClick={() => {
              history.push(`/admin/filial/conta/${branch.id}`);
              setOpenEditModal(!openEditModal);
            }}
          >
            {t('admin.branch.inspectAccount')}
          </button>
          <button
            className="edit"
            onClick={() => {
              history.push(`/admin/filial/${branch.id}`, {
                edit: true,
              });
              setOpenEditModal(!openEditModal);
            }}
          >
            {t('admin.branch.edit')}
          </button>
          <button
            className="edit"
            onClick={() => {
              setOpenEditModal(!openEditModal);
              if (branch.active) {
                inactivateBranch();
              } else {
                activateBranch();
              }
            }}
          >
            {t(`admin.branch.${branch.active ? 'inactivate' : 'activate'}`)}
          </button>
        </DropDownContainer>
      )}
    </Container>
  );
};
