import CustomButton from 'components/custom-button';
import _ from 'lodash';
import { Authority } from 'model/enums/authority';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { SubsidiariesService } from 'services/subsidiaries-service';
import { hasAuthorities } from 'shared/util/utils';
import { ReactComponent as PlusIconSvg } from 'images/white-plus.svg';
import { Status } from '../../components/status';
import { TableId, TableValues } from '../../components/table-list';
import { EditContainer } from './styles';
import { NewTableList } from '../../components/table-list-new';
import { PageableResponse } from 'model/pageable';
import { MoreMenu } from '../../components/more-menu';
import { TitleHeader } from './header';
import { BranchStatusTypes } from 'model/enums/branch-status';
import { OrganizationSubsidiaries } from 'model/landing-page';
import { useAdmin } from 'provider/admin-general';
import { useHistory } from 'react-router-dom';
import { Subsidiary } from 'model/subsidiaries';

const filterStatusValues = [BranchStatusTypes.ACTIVE, BranchStatusTypes.INACTIVE];

export const BranchList = () => {
  const { sort } = useAdmin();

  const [subsidariesList, setSubsidariesList] = useState<Subsidiary[]>([]);
  const [searchName, setSearchName] = useState('');
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const account = useSelector((state: IRootState) => state.authentication.account);
  const { t } = useTranslation();
  const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false);
  const [listResponse, setListResponse] = useState<PageableResponse<Subsidiary>>();

  const history = useHistory();

  useEffect(() => {
    getBranchList(searchName, page);
  }, [searchName, page, sort]);

  const getBranchList = async (searchName?: string, page?: number, activeStatus?: string) => {
    setIsLoadingTable(true);

    const res = await SubsidiariesService().getOrganizationSubsidiaries(searchName, 10, page, activeStatus, sort);
    setIsLoadingTable(false);

    setListResponse(res);
    setSubsidariesList(res.content);
    setPage(res.pageable.pageNumber);
    setTotalPages(res.totalPages);
  };

  const setTableValues = () => {
    const tableValues: TableValues[] = [
      {
        fullLength: true,
        header: t('admin.dashboard.tableHeaders.name'),
        values: subsidariesList.map(mt => mt.name ?? ''),
        ids: subsidariesList.filter(subsidiary => subsidiary.id != null).map(subsidiary => ({ first: subsidiary.id! })),
        onClick: (ids?: TableId) => {
          if (ids) {
            history.push(`/admin/filial/${ids.first}`);
          }
        },
      },
      {
        header: t('register.inputs.cnpj'),
        values: subsidariesList.map(mt => mt.cnpj ?? ''),
        ids: subsidariesList.filter(subsidiary => subsidiary.id != null).map(subsidiary => ({ first: subsidiary.id! })),
        onClick: (ids?: TableId) => {
          if (ids) {
            history.push(`/admin/filial/${ids.first}`);
          }
        },
      },
      {
        header: t('admin.dashboard.tableHeaders.accountStatus'),
        values: subsidariesList.map(mt => <Status text={t(`enums.subsidiaryStatus.${mt.status}`)} color={mt.status?.toString() ?? ''} />),
        ids: subsidariesList.filter(signature => signature.id != null).map(signature => ({ first: signature.id! })),
        onClick: (ids?: TableId) => {
          if (ids) {
            history.push(`/admin/filial/${ids.first}`);
          }
        },
      },
      {
        header: t('admin.dashboard.tableHeaders.status'),
        values: subsidariesList.map(mt => (
          <Status text={t(`enum.filterAllStatus.${mt.active ? 'ACTIVE' : 'INACTIVE'}`)} color={mt.active ? 'ACTIVE' : 'INACTIVE'} />
        )),
        ids: subsidariesList.filter(signature => signature.id != null).map(signature => ({ first: signature.id! })),
        onClick: (ids?: TableId) => {
          if (ids) {
            history.push(`/admin/filial/${ids.first}`);
          }
        },
      },
      {
        header: '',
        values: subsidariesList.map(mt => (
          <EditContainer key={mt.id}>
            {hasAuthorities(account?.authorities, [Authority.ROLE_ADMIN, Authority.ROLE_INTERNAL_ADMIN]) && <MoreMenu branch={mt} />}
          </EditContainer>
        )),
        fullLength: false,
        maxWidth: 'fit-content',
      },
    ];

    return tableValues;
  };

  const updateSearchName = (name: string) => {
    setSearchName(name);
  };

  const setNewPage = _.debounce((newPage: number) => {
    if (newPage >= totalPages) {
      return setPage(page);
    }
    setPage(newPage);
  }, 500);

  const { statusFilters } = useAdmin();

  const setStatusFilter = () => {
    if (statusFilters.length == 1) {
      getBranchList(searchName, page, statusFilters[0]);
    } else {
      getBranchList(searchName, page);
    }
  };

  const cleanAppliedFilters = () => {
    getBranchList(searchName);
  };

  return (
    <>
      <TitleHeader
        title={t('admin.dashboard.branch')}
        customButton={
          hasAuthorities(account?.authorities, [Authority.ROLE_ADMIN, Authority.ROLE_INTERNAL_ADMIN]) ? (
            <CustomButton
              onClick={() => {
                history.push(`/admin/criar-filial`);
              }}
            >
              <PlusIconSvg />
              {t('admin.dashboard.branchButton')}
            </CustomButton>
          ) : undefined
        }
        setSearch={updateSearchName}
        setFilters={setStatusFilter}
        filterStatusValues={filterStatusValues}
        cleanFilters={cleanAppliedFilters}
      />

      <NewTableList
        setLoadingTable={setIsLoadingTable}
        loadingTable={isLoadingTable}
        tableValues={setTableValues()}
        page={page}
        setNewPage={setNewPage}
        noPagination={totalPages === 1}
        signaturesResponse={listResponse}
      />
    </>
  );
};
